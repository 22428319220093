<template>
  <div class="flex flex-col gap-2.5">
    <ui-typography
      tag="h3"
      variant="h3"
      :text="label"
      class="sm:text-start text-center"
      :class="{ 'text-error': errorMessage }"
    />
    <ui-fields-input-search
      v-model="search"
      sync-v-model
      enabled-controlled
      placeholder="Find your interest"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="searchResults.length"
        class="flex flex-col gap-2.5"
      >
        <common-forms-parts-interests-list-item
          v-for="interestsGroup in searchResults"
          :key="interestsGroup.related_id + 'related-interest'"
          :interests-group="interestsGroup"
          :hide-create="hideCreate"
          :name="name"
        />
      </div>
      <ui-parts-interest-list-search-empty v-else />
    </transition>
  </div>
</template>

<script setup lang="ts">
  import { InterestableType, type TInterestableType } from '~/globals/interest';

  type Props = {
    data: Interest[];
    source?: User | Room;
    name?: string;
    label?: string;
    hideCreate?: boolean;
    interestableType?: TInterestableType;
  };
  const props = withDefaults(defineProps<Props>(), {
    name: 'interest_ids',
    label: 'Choose Your Interests',
    interestableType: InterestableType.USER
  });

  const { searchResults, search, errorMessage } = createInterestsList(
    useProp(props, 'data'),
    useProp(props, 'source'),
    useProp(props, 'name'),
    useProp(props, 'interestableType')
  );
</script>
