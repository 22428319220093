<template>
  <common-forms-parts-validate-interests-list-item
    :interests-group="interestsGroup"
    :name="name"
  >
    <ui-button
      v-if="!hideCreate && user?.subscription_plan.limitations.custom_interest"
      icon-left="add"
      size="md-icon"
      rounded="full"
      variant="secondary-outline"
      @click="openCreateModal(interestsGroup.id)"
    />
  </common-forms-parts-validate-interests-list-item>
</template>

<script setup lang="ts">
  type Props = {
    interestsGroup: InterestGroup;
    name: string;
    hideCreate?: boolean;
  };

  defineProps<Props>();

  const { openCreateModal } = useInterestsList();
  const { user } = useAuthUser();
</script>
